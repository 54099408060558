import actions from './actions';

const {
  LOAD_OFFERS_BEGIN,
  LOAD_OFFERS_SUCCESS,
  LOAD_OFFERS_ERR,

  FILTER_PROJECT_BEGIN,
  FILTER_PROJECT_SUCCESS,
  FILTER_PROJECT_ERR,

  SORTING_PROJECT_BEGIN,
  SORTING_PROJECT_SUCCESS,
  SORTING_PROJECT_ERR,
} = actions;

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const offerReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOAD_OFFERS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOAD_OFFERS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case LOAD_OFFERS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FILTER_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FILTER_PROJECT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case FILTER_PROJECT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SORTING_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SORTING_PROJECT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case SORTING_PROJECT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { offerReducer };

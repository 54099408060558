const actions = {
  CHAT_BEGIN: 'CHAT_BEGIN',
  CHAT_SUCCESS: 'CHAT_SUCCESS',
  CHAT_ERR: 'CHAT_ERR',
  SELECT_CHAT_SUCCESS: 'SELECT_CHAT_SUCCESS',
  SELECT_CHAT_ERROR: 'SELECT_CHAT_ERROR',
  UPDATE_CHAT_BEGIN: 'UPDATE_CHAT_BEGIN',
  UPDATE_CHAT_SUCCESS: 'UPDATE_CHAT_SUCCESS',
  UPDATE_CHAT_ERR: 'UPDATE_CHAT_ERR',

  chatBegin: () => {
    return {
      type: actions.CHAT_BEGIN,
    };
  },

  chatSuccess: data => {
    return {
      type: actions.CHAT_SUCCESS,
      data,
    };
  },

  chatErr: err => {
    return {
      type: actions.CHAT_ERR,
      err,
    };
  },

  updateChatBegin: () => {
    return {
      type: actions.UPDATE_CHAT_BEGIN,
    };
  },

  updateChatSuccess: data => {
    return {
      type: actions.UPDATE_CHAT_SUCCESS,
      data,
    };
  },

  updateChatErr: err => {
    return {
      type: actions.UPDATE_CHAT_ERR,
      err,
    };
  },
  selectChatSuccess: id => {
    return {
      type: actions.SELECT_CHAT_SUCCESS,
      id,
    };
  },
  selectChatErr: err => {
    return {
      type: actions.SELECT_CHAT_ERR,
      err,
    };
  },
};

export default actions;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Radio, Row } from 'antd';
import { useSelector } from 'react-redux';
import { MaskedInput } from 'antd-mask-input';
import { BasicFormWrapper } from '../../container/styled';
import { Cards } from '../../components/cards/frame/cards-frame';

const Address = props => {
  const { form } = props;

  const { user } = useSelector(state => {
    return {
      user: state.user,
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    form.setFieldsValue({
      ...user,
    });
  }, [form, user]);

  return (
    <BasicFormWrapper className="basic-form-inner">
      <Form form={form}>
        <Cards title="Adresse">
          <Row gutter={[8, 16]}>
            <Col xs={24}>
              <Form.Item
                name="address"
                label="Adresse"
                rules={[{ required: true, message: 'Votre adresse est obligatoire' }]}
              >
                <Input placeholder="Addresse" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="zipcode"
                label="Code postal"
                rules={[{ required: true, message: 'Votre code postal est obligatoire' }]}
              >
                <MaskedInput mask="00000" placeholder="Code Postal" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="city"
                label="Commune"
                rules={[{ required: true, message: 'Votre commune est obligatoire' }]}
              >
                <Input placeholder="Commune" />
              </Form.Item>
            </Col>
          </Row>
        </Cards>
        <Cards title="Mobilité">
          <p>Je suis prêt à me déplacer à quelle distance de mon domicile ?</p>
          <Form.Item name="mobility">
            <Radio.Group size="normal">
              <Radio.Button value={10}>Moins de 10km</Radio.Button>
              <Radio.Button value={25}>Moins de 25km</Radio.Button>
              <Radio.Button value={50}>Moins de 50km</Radio.Button>
              <Radio.Button value={100}>Plus de 50km</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Cards>
      </Form>
    </BasicFormWrapper>
  );
};

Address.defaultProps = {};

Address.propTypes = {
  form: PropTypes.any,
};

export { Address };

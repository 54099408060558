const actions = {
  LOAD_OFFERS_BEGIN: 'LOAD_OFFERS_BEGIN',
  LOAD_OFFERS_SUCCESS: 'LOAD_OFFERS_SUCCESS',
  LOAD_OFFERS_ERR: 'LOAD_OFFERS_ERR',

  FILTER_OFFER_BEGIN: 'FILTER_OFFER_BEGIN',
  FILTER_OFFER_SUCCESS: 'FILTER_OFFER_SUCCESS',
  FILTER_OFFER_ERR: 'FILTER_OFFER_ERR',

  SORTING_OFFER_BEGIN: 'SORTING_OFFER_BEGIN',
  SORTING_OFFER_SUCCESS: 'SORTING_OFFER_SUCCESS',
  SORTING_OFFER_ERR: 'SORTING_OFFER_ERR',

  loadingOffersBegin: () => {
    return {
      type: actions.LOAD_OFFERS_BEGIN,
    };
  },

  loadingOffersSuccess: data => {
    return {
      type: actions.LOAD_OFFERS_SUCCESS,
      data,
    };
  },

  loadingOffersError: err => {
    return {
      type: actions.LOAD_OFFERS_ERR,
      err,
    };
  },

  filterOfferBegin: () => {
    return {
      type: actions.FILTER_OFFER_BEGIN,
    };
  },

  filterOfferSuccess: data => {
    return {
      type: actions.FILTER_OFFER_SUCCESS,
      data,
    };
  },

  filterOfferErr: err => {
    return {
      type: actions.FILTER_OFFER_ERR,
      err,
    };
  },

  sortingOfferBegin: () => {
    return {
      type: actions.SORTING_OFFER_BEGIN,
    };
  },

  sortingOfferSuccess: data => {
    return {
      type: actions.SORTING_OFFER_SUCCESS,
      data,
    };
  },

  sortingOfferErr: err => {
    return {
      type: actions.SORTING_OFFER_ERR,
      err,
    };
  },
};

export default actions;

import { combineReducers } from 'redux';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import { actionReducer } from './actionState/reducers';
import { chatReducer } from './chat/reducers';
import { userReducer } from './user/reducers';
import { offerReducer } from './offer/reducers';

const rootReducers = combineReducers({
  auth: authReducer,
  action: actionReducer,
  notification: readNotificationReducer,
  chat: chatReducer,
  user: userReducer,
  offers: offerReducer,
});

export default rootReducers;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Radio, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { BasicFormWrapper } from '../../container/styled';
import { JobSelect } from '../../container/job';
import { Cards } from '../../components/cards/frame/cards-frame';
import { saveUser } from '../../redux/user/userCreator';

const Skills = props => {
  const { form } = props;
  const dispatch = useDispatch();

  const { userId, job, jobTitle } = useSelector(store => {
    form.setFieldsValue({
      experience: store.user?.job?.experience,
    });
    return {
      userId: store.user?.id,
      job: store.user?.job,
      jobTitle: store.user?.job?.title,
      experience: store.user?.experience,
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [jobTitle]);

  const onSelect = item => {
    dispatch(saveUser({ id: userId, job: item }));
  };

  const selectExperience = event => {
    const { value } = event.target;
    job.experience = value;
    dispatch(saveUser({ id: userId, job }));
  };

  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col xs={24}>
            <Form form={form}>
              <Form.Item name="job" rules={[{ required: true, message: 'Votre métier est obligatoire' }]}>
                <JobSelect form={form} onSelect={onSelect} defaultValue={job?.label} />
              </Form.Item>
              {job?.code !== null && job?.code !== undefined && (
                <Cards title="Expérience">
                  <p>Pendant combien de temps avez vous exercé cette profession ?</p>
                  <Form.Item
                    name="experience"
                    rules={[{ required: true, message: 'Votre expérience est obligatoire' }]}
                  >
                    <Radio.Group size="normal" onChange={selectExperience}>
                      <Radio.Button value={5}>Plus de 5 ans</Radio.Button>
                      <Radio.Button value={10}>Plus de 10 ans</Radio.Button>
                      <Radio.Button value={15}>Plus de 15 ans</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Cards>
              )}
            </Form>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
};

Skills.defaultProps = {};

Skills.propTypes = {
  form: PropTypes.any,
};

export { Skills };

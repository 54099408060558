import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'antd';
import { useSelector } from 'react-redux';
import { BasicFormWrapper } from '../../container/styled';
import { Cards } from '../../components/cards/frame/cards-frame';

const Availability = props => {
  const { form } = props;

  const { user } = useSelector(state => {
    return {
      user: state.user,
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    form.setFieldsValue({
      ...user,
    });
  }, [form, user]);

  return (
    <BasicFormWrapper className="basic-form-inner">
      <Form form={form}>
        <Cards title="Disponibilité">
          <p>Je suis prêt à travailler à quel rythme hedbomadaire ?</p>
          <Form.Item name="weeklyavailability">
            <Radio.Group size="normal">
              <Radio.Button value={1}>1 jour / semaine</Radio.Button>
              <Radio.Button value={3}>3 jours / semaine</Radio.Button>
              <Radio.Button value={5}>Plein temps</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <p>Je suis prêt à travailler à quel rythme annuel ?</p>
          <Form.Item name="monthlyavailability">
            <Radio.Group size="normal">
              <Radio.Button value={1}>3 mois dans l&apos;année</Radio.Button>
              <Radio.Button value={3}>6 mois dans l&apos;année</Radio.Button>
              <Radio.Button value={6}>9 mois dans l&apos;année</Radio.Button>
              <Radio.Button value={12}>Toute l&apos;année</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Cards>
      </Form>
    </BasicFormWrapper>
  );
};

Availability.defaultProps = {};

Availability.propTypes = {
  form: PropTypes.any,
};

export { Availability };

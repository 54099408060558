const actions = {
  SAVE_USER: 'SAVE_USER',

  setUserAction: user => {
    return {
      type: actions.SAVE_USER,
      user,
    };
  },
};

export default actions;

const actions = {
  ACTION_BEGIN: 'ACTION_BEGIN',
  ACTION_SUCCESS: 'ACTION_SUCCESS',
  ACTION_ERR: 'ACTION_ERR',
  ACTION_CLEAR: 'ACTION_CLEAR',

  actionBegin: () => {
    return {
      type: actions.ACTION_BEGIN,
    };
  },

  actionClear: () => {
    return {
      type: actions.ACTION_CLEAR,
    };
  },

  actionrSuccess: (title, description) => {
    return {
      type: actions.ACTION_SUCCESS,
      title,
      description,
    };
  },

  actionErr: (title, description, err) => {
    return {
      title,
      description,
      type: actions.ACTION_ERR,
      err,
    };
  },
};

export default actions;

import Cookies from 'js-cookie';
import { removeItem, setItem } from '../../utility/localStorageControl';
import { Log } from '../../utility/log';

const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',
  FIREBASE_USER_LOADED: 'FIREBASE_USER_LOADED',
  FIREBASE_USER_LOAD_ERROR: 'FIREBASE_USER_LOADING_ERROR',

  loginBegin: () => {
    Cookies.remove('logedIn');
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: data => {
    if (!data) {
      Cookies.remove('logedIn');
    }
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: err => {
    Cookies.remove('logedIn');
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: data => {
    removeItem('access_token');
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: err => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },

  firebaseUserLoaded: user => {
    Log.log('FB User Loaded');
    if (user != null && user.accessToken != null) {
      Log.log(user.accessToken);
      setItem('access_token', user.accessToken);
      Cookies.set('logedIn', true);
    } else {
      Cookies.remove('logedIn');
    }
    return {
      type: actions.FIREBASE_USER_LOADED,
      user,
    };
  },

  firebaseUserLoadingError: err => {
    return {
      type: actions.READ_MESSAGE_ERR,
      err,
    };
  },
};

export default actions;

import actions from './actions';

const { SAVE_USER } = actions;

const initState = {
  uid: null,
};

const userReducer = (state = initState, action) => {
  const { type, user } = action;
  switch (type) {
    case SAVE_USER:
      return {
        ...state,
        ...user,
      };
    default:
      return state;
  }
};

export { userReducer };

import { notification } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clear } from '../redux/actionState/actionCreator';

const ActionNotificationControl = () => {
  const dispatch = useDispatch();

  const { success, error, title, description } = useSelector(state => {
    return {
      success: state.action.success,
      error: state.action.error,
      title: state.action.title,
      description: state.action.description,
    };
  });

  useEffect(() => {
    if (error != null && error) {
      notification.error({
        message: title != null ? title : 'Erreur',
        description,
      });
    } else if (success != null && success) {
      notification.success({
        message: title != null ? title : 'Action exécutée avec succès',
        description,
      });
    }
    dispatch(clear());
  }, [success, error, title, description, dispatch]);

  return <></>;
};

export default ActionNotificationControl;

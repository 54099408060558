import React from 'react';
import { Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { AutoCompleteStyled } from './style';

const renderItem = (title, id, code) => {
  return {
    value: title,
    id,
    code,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
      </div>
    ),
  };
};

const AutoComplete = props => {
  const {
    customComponent,
    patterns,
    width,
    onSearch,
    dataSource,
    placeholder,
    loading,
    onSelect,
    defaultValue,
  } = props;

  const content =
    dataSource?.length > 0 &&
    dataSource.map(group => {
      const { title, id, code } = group;
      return {
        options: [renderItem(title, id, code)],
      };
    });

  const onSearching = searchText => {
    onSearch(searchText);
  };

  const onSelectInternal = (value, item) => {
    if (onSelect !== null) {
      onSelect(item);
    }
  };

  return customComponent ? (
    <AutoCompleteStyled dataSource={dataSource} style={{ width }} onSelect={onSelectInternal} onSearch={onSearching}>
      {customComponent}
    </AutoCompleteStyled>
  ) : patterns ? (
    <AutoCompleteStyled
      className="certain-category-search"
      dropdownClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ width: 300 }}
      defaultValue={defaultValue}
      style={{ width }}
      options={content}
      placeholder={placeholder}
      onSearch={onSearching}
      onSelect={onSelectInternal}
    >
      <Input
        suffix={
          <Button className="search-btn" style={{ marginRight: -20 }} type="primary">
            {loading ? <LoadingOutlined /> : <SearchOutlined />}
          </Button>
        }
      />
    </AutoCompleteStyled>
  ) : (
    <AutoCompleteStyled
      dataSource={dataSource}
      style={{ width }}
      onSelect={onSelectInternal}
      defaultValue={defaultValue}
      onSearch={onSearching}
      placeholder={placeholder}
    />
  );
};

AutoComplete.defaultProps = {
  width: '350px',
  placeholder: 'Input here',
  loading: false,
};

AutoComplete.propTypes = {
  customComponent: PropTypes.node,
  patterns: PropTypes.bool,
  width: PropTypes.string,
  onSearch: PropTypes.func,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  defaultValue: PropTypes.string,
};

export { AutoComplete };

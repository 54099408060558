import actions from './actions';

const { actionrSuccess, actionErr, actionClear } = actions;

const success = (title, description) => {
  return async dispatch => {
    dispatch(actionrSuccess(title, description));
  };
};

const error = (title, description, err) => {
  return async dispatch => {
    dispatch(actionErr(title, description, err));
  };
};

const clear = () => {
  return async dispatch => {
    dispatch(actionClear());
  };
};

export { success, error, clear };

import actions from './actions';
import staticData from '../../demoData/chatData.json';

const initialState = {
  data: staticData.chat,
  selected: null,
  loading: false,
  error: null,
};

const {
  CHAT_BEGIN,
  CHAT_SUCCESS,
  CHAT_ERR,
  SELECT_CHAT_SUCCESS,
  SELECT_CHAT_ERROR,

  UPDATE_CHAT_BEGIN,
  UPDATE_CHAT_SUCCESS,
  UPDATE_CHAT_ERR,
} = actions;

const chatReducer = (state = initialState, action) => {
  const { type, data, err, id } = action;
  switch (type) {
    case UPDATE_CHAT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case UPDATE_CHAT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case UPDATE_CHAT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case CHAT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case CHAT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case CHAT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case SELECT_CHAT_SUCCESS:
      return {
        ...initialState,
        selected: id,
        loading: false,
      };
    case SELECT_CHAT_ERROR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { chatReducer };

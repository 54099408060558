import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Col, Form, Input, Radio, Row } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { MaskedInput } from 'antd-mask-input';
import { BasicFormWrapper } from '../../container/styled';
import { Cards } from '../../components/cards/frame/cards-frame';

const Infos = props => {
  const { form } = props;

  const { user } = useSelector(state => {
    return {
      user: state.user,
    };
  });

  const [checked, setChecked] = useState(false);

  const onCheckboxChange = async e => {
    await setChecked(e.target.checked);
    form.validateFields(['checkbox']);
  };

  const validation = (rule, value, callback) => {
    if (checked) {
      return callback();
    }
    return callback("Accepter les conditions d'utilisation du service est obligatoire");
  };

  useEffect(() => {
    form.setFieldsValue({
      ...user,
      birthdate: user.birthdate != null ? moment(user.birthdate) : null,
    });
    window.scrollTo(0, 0);
  }, [form, user]);

  return (
    <BasicFormWrapper className="basic-form-inner">
      <div className="atbd-form-checkout">
        <Row justify="center">
          <Col xs={24}>
            <Form form={form} name="info">
              <Cards title="Informations">
                <Form.Item
                  name="civility"
                  label="Civilité"
                  rules={[{ required: true, message: 'Votre civilité est obligatoire' }]}
                >
                  <Radio.Group size="small">
                    <Radio.Button value="M">Monsieur</Radio.Button>
                    <Radio.Button value="F">Madame</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="lastname"
                  label="Nom"
                  rules={[{ required: true, message: 'Votre nom est obligatoire' }]}
                >
                  <Input placeholder="Nom" />
                </Form.Item>
                <Form.Item
                  name="firstname"
                  label="Prénom"
                  rules={[{ required: true, message: 'Votre prénom est obligatoire' }]}
                >
                  <Input placeholder="Prénom" />
                </Form.Item>
                <Form.Item
                  name="birthdate"
                  label="Date de naissance"
                  rules={[{ required: true, message: 'Votre date de naissance est obligatoire' }]}
                >
                  <MaskedInput mask="00/00/0000" placeholder="JJ/MM/AAAA" />
                </Form.Item>
              </Cards>
              <Cards title="Coordonnées">
                <Form.Item
                  name="phone"
                  rules={[{ type: 'phone' }, { required: true, message: 'Votre numéro de mobile est obligatoire' }]}
                  label="Numéro de Mobile"
                >
                  <MaskedInput mask="00 00 00 00 00" placeholder="06 07 08 09 10" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[{ type: 'email', message: "Cet email n'est pas valide" }]}
                  label="Adresse email"
                >
                  <Input placeholder="name@gmail.com" />
                </Form.Item>
                <Form.Item name="checkbox" rules={[{ validator: validation }]}>
                  <Checkbox checked={checked} onChange={onCheckboxChange}>
                    J&apos;accepte que ces données soient receuillies par Silvex SAS pour délivrer son service
                    d&apos;accompagnement des retraités actifs dans la recherche de missions en entreprise.
                    <br />
                    <a>Plus de détails</a>
                  </Checkbox>
                </Form.Item>
              </Cards>
            </Form>
          </Col>
        </Row>
      </div>
    </BasicFormWrapper>
  );
};

Infos.defaultProps = {};

Infos.propTypes = {
  form: PropTypes.any,
};

export { Infos };

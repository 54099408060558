import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import store from './redux/store';

import './static/css/style.css';
import config from './config/config';
import ActionNotificationControl from './utility/actionNotificationControl';

import RegistrationWizard from './pages/registration';

const { theme } = config;

const ProviderConfig = () => {
  const { rtl, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: true,
      topMenu: true,
      isLoggedIn: true, // state.auth.login,
    };
  });

  /*
  const dispatch = useDispatch();

  useEffect(() => {
    
    onAuthStateChanged(getAuth(firebase), user => {
      if (user) {
        // user is logged in, send the user's details to redux, store the current user in the state
        dispatch(storeFirebaseUser(user));
      } else {
        dispatch(storeFirebaseUser(null));
      }
    });
  }, [dispatch, isLoggedIn]);
*/
  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <ActionNotificationControl />
        <Router basename={process.env.PUBLIC_URL}>
          {/* 👇️ only match this when no other routes match
          {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute component={Routes} path="/" />}
           */}
          <Route path="/" component={RegistrationWizard} />
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);

import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { error } from '../actionState/actionCreator';

const { setUserAction } = actions;

const saveUser = _user => {
  const user = _user;
  // Delete the skils items from object
  delete user.skills;

  return async dispatch => {
    try {
      const resp = await DataService.post('/user', user);
      if (resp !== undefined) {
        if (resp.status === 201) {
          dispatch(setUserAction({ ...user, ...resp.data }));
        } else {
          dispatch(
            error(
              'Erreur lors de la sauvegarde',
              `API response Code ${resp.status}`,
              new Error(`API response Code ${resp.status}`),
            ),
          );
        }
      } else {
        dispatch(error('Erreur lors de la sauvegarde', `API pas de réponse`, new Error(`API pas de répoonse`)));
      }
    } catch (err) {
      dispatch(error('Erreur lors de la sauvegarde', err.message, err));
    }
  };
};

export { saveUser };

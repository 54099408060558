import actions from './actions';

const { ACTION_BEGIN, ACTION_SUCCESS, ACTION_ERR, ACTION_CLEAR } = actions;

const initState = {
  loading: false,
  success: false,
  error: null,
  title: null,
  description: null,
};

const actionReducer = (state = initState, action) => {
  const { type, title, description, err } = action;
  switch (type) {
    case ACTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        title,
        description,
      };
    case ACTION_ERR:
      return {
        ...state,
        loading: false,
        success: false,
        error: err,
        title,
        description,
      };
    case ACTION_CLEAR:
      return {
        ...state,
        loading: false,
        success: null,
        error: null,
        title: null,
        description: null,
      };
    default:
      return state;
  }
};

export { actionReducer };

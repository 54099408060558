import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Wizard, WizardWrapper } from './style';
import { Infos } from './info-perso';
import { Skills } from './skills';
import { Address } from './address';
import { Availability } from './availability';
import { Steps } from '../../components/steps/steps';
import { error, success } from '../../redux/actionState/actionCreator';
import { Log } from '../../utility/log';
import { saveUser } from '../../redux/user/userCreator';

const RegistrationWizard = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const { userId } = useSelector(state => {
    return {
      userId: state.user.id,
    };
  });

  const [state, setState] = useState({
    current: 0,
    values: {
      id: userId,
    },
  });

  const { current, values } = state;

  const saveData = async data => {
    Log.log('Saving Data ...', data);
    dispatch(saveUser(data));
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    fbq('track', 'PageView');
  });

  const next = async () => {
    form
      .validateFields()
      .then(formValues => {
        Log.log('Sending Values to Next Step', formValues);
        const newValues = { ...values, ...formValues, id: userId };

        saveData(newValues)
          .then(() => {
            setState({
              ...state,
              values: newValues,
              status: 'process',
              current: current + 1,
            });
          })
          .catch(err => {
            Log.log(err);
            window.scrollTo(0, 0);
          });
      })
      .catch(err => {
        Log.log(err);
        window.scrollTo(0, 0);
      });
  };

  const prev = () => {
    setState({
      ...state,
      status: 'process',
      current: current - 1,
    });
    // eslint-disable-next-line no-undef
    fbq('track', 'PageView');
  };

  const done = () => {
    form
      .validateFields()
      .then(formValues => {
        const newValues = { ...values, ...formValues, id: userId };

        Log.log('Sending Values to Final Step', newValues);
        setState({
          ...state,
          values: newValues,
        });
        saveData(newValues)
          .then(() => {
            dispatch(success('Vous êtes enregistrés', "Merci de rejoindre la communauté des silv'Experts"));
            // eslint-disable-next-line no-undef
            fbq('track', 'Purchase', { currency: 'EUR', value: 10.0 });
            // Trigger the event
            window.location.href = 'https://www.silvex.io/form-ok';
          })
          .catch(err => {
            Log.error(err);
            window.scrollTo(0, 0);
          });

        history.replace('/');
      })
      .catch(err => {
        Log.log(err);
        dispatch(
          error(
            'Erreur lors de votre enregistrement',
            "Une erreur technique s'est produite. Merci d'essayer ultérieurement",
          ),
        );
      });
  };

  return (
    <WizardWrapper>
      <Wizard>
        <Steps
          isswitch
          isvertical
          current={current}
          steps={[
            {
              title: (
                <div className="wizard-item">
                  <h2>Qui êtes vous ?</h2>
                  <p>Donnez nous quelques informations sur vous pour mieux vous connaitre.</p>
                </div>
              ),
              content: <Infos form={form} />,
            },
            {
              title: (
                <div className="wizard-item silex-background">
                  <h2>Quelle est votre expérience ?</h2>
                  <p>Précisez votre profession et vos savoir-faire.</p>
                </div>
              ),
              content: <Skills form={form} />,
            },
            {
              title: (
                <div className="wizard-item silex-background">
                  <h2>Dans quelle région vivez vous ?</h2>
                  <p>Donnez nous des précisions sur votre mobilité.</p>
                </div>
              ),
              content: <Address form={form} />,
              isfinished: true,
            },
            {
              title: (
                <div className="wizard-item silex-background">
                  <h2>A quel rythme souhaitez-vous reprendre une activité ?</h2>
                  <p>Avec Silvex vous choisissez votre rythme de travail.</p>
                </div>
              ),
              content: <Availability form={form} />,
              isfinished: true,
            },
          ]}
          onNext={next}
          onPrev={prev}
          onDone={done}
        />
      </Wizard>
    </WizardWrapper>
  );
};

export default RegistrationWizard;

/* eslint-disable no-console */
class Log {
  static log = (text, params) => {
    if (params !== undefined) {
      console.log(text, params);
    } else {
      console.log(text);
    }
  };

  static error = (text, params) => {
    // eslint-disable-next-line no-console
    console.error(text, params);
  };
}

export { Log };

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Form } from 'antd';
import { AutoComplete } from '../components/autoComplete/autoComplete';
import { Cards } from '../components/cards/frame/cards-frame';
import { DataService } from '../config/dataService/dataService';
import { Log } from '../utility/log';

const JobSelect = props => {
  const { width, placeHolder, onSelect, form, defaultValue } = props;

  const [state, setState] = useState({
    data: [],
    loading: false,
  });

  const { data, loading } = state;

  // highlight-starts
  const debouncedSearch = useCallback(
    debounce(async value => {
      let result = [];
      try {
        const resp = await DataService.get(`/professions?query=${value}`);
        if (resp.status === 200) {
          result = resp.data;
        }
      } catch (error) {
        Log.error(error);
      }
      if (value.length > 0) {
        if (result.length === 0) {
          setState({
            data: [{ title: `Aucun métier trouvé pour : ${value}` }],
            loading: false,
          });
        } else {
          setState({
            data: result,
            loading: false,
          });
        }
      } else {
        setState({
          data: [],
          loading: false,
        });
      }
    }, 500),
    [], // will be created only once initially
  );
  // highlight-ends

  const onSearch = value => {
    setState({
      loading: true,
    });
    if (value.length > 3) {
      debouncedSearch(value);
    } else {
      setState({
        data: [],
        loading: false,
      });
    }
  };

  const onSelectPrivate = item => {
    form.setFieldsValue({ job: { id: item.id, title: item.value, code: item.code } });
    if (onSelect !== undefined && onSelect !== null) {
      onSelect({ id: item.id, title: item.value, code: item.code });
    }
  };
  return (
    <Cards title="Métier">
      <p>Commencer à taper le nom de votre métier et choissiez dans la liste le métier correspondant</p>
      <Form.Item name="job">
        <AutoComplete
          dataSource={data}
          onSearch={onSearch}
          onSelect={onSelectPrivate}
          width={width}
          patterns
          patternButtons
          placeholder={placeHolder}
          loading={loading}
          defaultValue={defaultValue}
        />
      </Form.Item>
    </Cards>
  );
};

JobSelect.defaultProps = {
  width: '100%',
  placeHolder: 'Taper le nom de votre métier',
};

JobSelect.propTypes = {
  width: PropTypes.string,
  placeHolder: PropTypes.string,
  onSelect: PropTypes.func,
  form: PropTypes.any,
  defaultValue: PropTypes.string,
};

export { JobSelect };
